import React, { Component } from 'react';
import client from '../utils/feathers';

import Loading from './loading-spinner';

import { Form, FormGroup, Label, Container, Row, Col, InputGroup, Input, Button } from 'reactstrap';

export default class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	// componentDidMount() {
	//   if(client.authenticated)
	//     client.logout();
	// }

	updateField(name, ev) {
		this.setState({ [name]: ev.target.value });
	}

	async login() {
		const { email, password } = this.state;

		this.setState({
			...this.state,
			loading: true
		});

		return client.authenticate({
			strategy: 'local',
			email, password
		}).then(data => {
			this.setState({ error: false, loading: false });
			if (this.props.onLogin) {
				this.props.onLogin(data);
		}
		}).catch(error => {
			this.setState({ error });
		});
	}

	signup() {
		const { email, password } = this.state;

		this.setState({
			...this.state,
			loading: true
		});

		return client.service('users')
			.create({ email, password })
			.then(() => this.login());
	}


	render() {
		return <Container>
		<Row>
			<Col className="text-center heading" sm="12" md="6" lg="5" style={{ margin: "0 auto"}}>
				<h1 className="font-100">Log in or signup</h1>
				<p style={{color:"red"}}>{this.state.error && this.state.error.message}</p>
				{this.state.loading && <Loading/>}
			</Col>
		</Row>
		<Row>
			<Col className="text-center heading" sm="12" md="6" lg="5" style={{ margin: "0 auto"}}>
				<form className="form" onSubmit={() => this.login()}>
					<FormGroup>
						<Label>Email</Label>
						<Input type="email" name="email" placeholder="Email" onChange={ev => this.updateField('email', ev)} />
					</FormGroup>

					<FormGroup>
						<Label>Password</Label>
						<Input type="password" name="password" placeholder="password" onChange={ev => this.updateField('password', ev)} />
					</FormGroup>

					<Button block={true} color="secondary" onClick={() => this.login()}>
						Log in
					</Button>

					<Button block={true} color="primary" onClick={() => this.signup()}>
						Signup
					</Button>
				</form>
			</Col>
		</Row>
		</Container>;
	}
}
