import React, { Component } from 'react'

import Layout from '../components/layout'
import Footer from '../components/footer'
import styles from './login.module.scss';

import Spinner from '../components/loading-spinner';
import client from '../utils/feathers';
import Login from '../components/login'
import SEO from '../components/seo'
import { navigate } from '../utils/navigate';
import { Button, Input, ButtonGroup, FormGroup, Col } from 'reactstrap';

class Page extends Component {
	constructor() {
		super();
		this.state = {
			saving: false,
			saved: false
		};
	}
	componentDidMount() {
		const lstate = this.props.location.state || {};
		if(lstate.logout) {
			setTimeout( () => client.logout(), 100);
		}

		let returnTo;
		if(lstate.from) {
			returnTo = lstate.from === "index" ? "/" : "/" + lstate.from;

			console.log("[loginPage] state gave returnTo=", returnTo);
			this.setState({ userProvidedReturnTo: true });

		} else {
			returnTo = "/";
		}

		this.setState({ returnTo });
	}

	async updateUser(field, id, value) {
		if(value) {
			this.setState({ saving: true, saved: false });
			const patch = await client.service('users').patch(id, { [field]: value });
			this.setState({ saving: false, saved: true });
			// console.log("[updateUser] ", { field, id, value, patch });
		}
	}

	render() {
		const { saving, saved } = this.state;
		return (
		<Layout page="login" authReturnTo={this.state.userProvidedReturnTo ? this.state.returnTo : undefined}>{auth => 
			<div className={styles.page}>
				<SEO title="Login" keywords={[`gatsby`, `application`, `react`]} />
				<section>
					{/* <h1></h1> */}
					{auth && <>
						<h1 className="mb-0">You're Logged In!</h1>
						<Col md="6" style={{margin:"-1rem auto 0"}}>
							{/* <div className="mt-0 alert alert-secondary">
								You can change your email address and/or your password using the fields below.
							</div> */}

							{this.state.editCreds && <>
								<FormGroup>
									<label>Change Your Email</label>
									<Input name="email"
										defaultValue={auth.user.email}
										onBlur={e => this.updateUser('email', auth.user.id, e.target.value)}
									/>
								</FormGroup>

								<FormGroup>
									<label>Change Password</label>
									<Input name="password"
										type="password"
										defaultValue=""
										onBlur={e => this.updateUser('password', auth.user.id, e.target.value)}
									/>
								</FormGroup>

								{saving && 
									<div className="alert alert-warning mt-3 mb-4">
										Saving ...
									</div>
								}

								{saved && 
									<div className="alert alert-success mt-3 mb-4">
										<b>Saved!</b>
									</div>
								}

								<Button block color="success" onClick={() => this.setState({ editCreds: false }) }>Done Editing</Button>
							</>}

							{/* <ButtonGroup> */}
								{!this.state.editCreds &&
									<Button block color="warning" onClick={() => this.setState({ editCreds: true }) }>Edit User / Pass</Button>
								}

								<Button block color="danger" onClick={() => client.logout() }>Logout</Button>
							{/* </ButtonGroup> */}
						</Col>
					</>}
					{!auth && <Login onLogin={() => navigate(this.state.returnTo) } /> }
				</section>
				<Footer/>
			</div>
		}</Layout>
	)}
}

export default Page
